import Repository from "@/api/Repository";

export default {
  async getRiepilogoTarget() {
    const url = "api/ministero/assegnazione-risorse/target";
    return (await Repository.get(url)).data;
  },
  async getRiepilogoFinanziario() {
    const url = `api/ministero/assegnazione-risorse/finanze`;
    return (await Repository.get(url)).data;
  },
  async getAllRichiesteAnticipo() {
    const url = `api/ministero/assegnazione-risorse/richieste-anticipo`;
    return (await Repository.get(url)).data;
  },
  async getSingolaRichiestaAnticipo(idRichiesta) {
    const url = `api/ministero/assegnazione-risorse/richieste-anticipo/${idRichiesta}`;
    return (await Repository.get(url)).data;
  },
  async getRiepilogoProgrammiOrientamento() {
    const url = `api/ministero/assegnazione-risorse/programmi-orientamento`;
    return (await Repository.get(url)).data;
  },
  getFileExcelRiepiloghi: async function (formBody) {
    const url = `api/ministero/assegnazione-risorse/excel`;
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await Repository.post(url, formBody, {
      headers,
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      const objectUrl = URL.createObjectURL(file);
      const linkToFile = document.createElement("a");
      linkToFile.href = objectUrl;
      linkToFile.download = "report_assegnazione_risorse.xlsx";
      linkToFile.click();
      URL.revokeObjectURL(file);
    }
  },
  async setStatoRichieste(formBody) {
    const url = `api/ministero/assegnazione-risorse/richieste-anticipo/aggiornamento-stati-erogazione`;
    const headers = { "Content-Type": "multipart/form-data" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async getFileExcelEstrazione(anno, idStrutture) {
    const url =
      idStrutture > 0
        ? `api/ministero/assegnazione-risorse/${anno}/estrazione?idStrutture=${idStrutture}`
        : `api/ministero/assegnazione-risorse/${anno}/estrazione`;
    return await Repository.get(url, {
      responseType: "blob",
    });
  },
  async uploadFile(anno, file) {
    const url = `api/ministero/assegnazione-risorse/${anno}/upload-file`;
    const headers = { "Content-Type": "multipart/form-data" };

    const res = await Repository.post(url, file, { headers });

    if (res.status === 201) {
      const fileResponse = new Blob([res.data], { type: "text/plain" });
      const objectUrl = URL.createObjectURL(fileResponse);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "errori_upload.txt";
      fileLink.click();
      URL.revokeObjectURL(fileResponse);
    }
  },
  async uploadFileDpRendiconti(anno, file) {
    const url = `api/ministero/assegnazione-risorse/${anno}/upload-file-DP-rendiconti`;
    const headers = { "Content-Type": "multipart/form-data" };

    const res = await Repository.post(url, file, { headers });

    if (res.status === 201) {
      const fileResponse = new Blob([res.data], { type: "text/plain" });
      const objectUrl = URL.createObjectURL(fileResponse);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = "errori_upload.txt";
      fileLink.click();
      URL.revokeObjectURL(fileResponse);
    }
  },
};
