<template>
  <v-container fluid>
    <v-card elevation="4">
      <v-card-title color="primary"
        >Assegnazione delle risorse e target</v-card-title
      >
      <v-card-subtitle>
        Sezione in cui è possibile visualizzare per singolo CUP l'assegnazione
        delle risorse e dei target.
      </v-card-subtitle>
      <v-main>
        <v-row>
          <v-col cols="2">
            <v-btn @click="esportaSuExcel" class="ma-2 pa-2">
              Esporta su excel
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2 my-auto">
            <v-progress-circular
              v-if="recuperoDati"
              :size="30"
              indeterminate
              color="blue"
            />
            <v-btn
              v-else
              @click="estrazione"
              class="ma-2 pa-2"
              :disabled="false"
            >
              <!-- esitoControlloRendicontoGiugno -->
              <!-- importoAmmessoRendicontoGiungno -->
              Estrazione dati
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2 my-auto">
            <DialogImportDatiSap @upload-completato="uploadCompletato()" />
          </v-col>
          <v-col cols="2 my-auto">
            <DialogImportDpRendicontoComponent
              @upload-completato="uploadCompletato()"
            />
          </v-col>
        </v-row>

        <v-main>
          <RiepilogoUnoAssegnazioneRisorse
            v-if="programmi && programmi.length"
            :programmi="programmi"
          />
          <v-progress-circular v-else indeterminate color="blue" />
        </v-main>
        <v-main>
          <RiepilogoDueAssegnazioneRisorse
            v-if="risorseFinanziare && risorseFinanziare.length"
            :risorse-finanziarie="risorseFinanziare"
          />
          <v-progress-circular v-else indeterminate color="blue" />
        </v-main>
        <v-main>
          <RiepilogoTreAssegnazioneRisorse
            v-if="target.length"
            :target="target"
          />
          <v-progress-circular v-else indeterminate color="blue" />
        </v-main>
        <v-main>
          <RiepilogoQuattroAssegnazioneRisorse
            v-if="richiesteAnticipo.length"
            :richieste-anticipo="richiesteAnticipo"
          />
          <v-progress-circular v-else indeterminate color="blue" />
        </v-main>
      </v-main>
    </v-card>

    <v-snackbar :color="snackbar.color" v-model="snackbar.value">
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import RiepilogoTreAssegnazioneRisorse from "@/components/AssegnazioneTargetControlli/RiepilogoTreAssegnazioneRisorse.vue";
import RiepilogoQuattroAssegnazioneRisorse from "@/components/AssegnazioneTargetControlli/RiepilogoQuattroAssegnazioneRisorse.vue";
import RiepilogoUnoAssegnazioneRisorse from "@/components/AssegnazioneTargetControlli/RiepilogoUnoAssegnazioneRisorseComponent.vue";
import RiepilogoDueAssegnazioneRisorse from "@/components/AssegnazioneTargetControlli/RiepilogoDueAssegnazioneRisorseComponent.vue";
import AssegnazioneRisorseRepository from "@/api/ministero/AssegnazioneRisorseRepository";
import DialogImportDatiSap from "@/components/AssegnazioneTargetControlli/DialogImportDatiSapComponent.vue";
import DialogImportDpRendicontoComponent from "@/components/AssegnazioneTargetControlli/DialogImportDpRendicontoComponent.vue";
export default {
  name: "assegnazioneRisorseTarget",
  components: {
    DialogImportDpRendicontoComponent,
    DialogImportDatiSap,
    RiepilogoUnoAssegnazioneRisorse,
    RiepilogoQuattroAssegnazioneRisorse,
    RiepilogoTreAssegnazioneRisorse,
    RiepilogoDueAssegnazioneRisorse,
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["anno", "idStrutture"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
      "isRoleRup",
    ]),
  },
  data: () => ({
    recuperoDati: false,
    loadingComponent: false,
    target: [],
    richiesteAnticipo: [],
    programmi: [],
    allCup: [],
    risorseFinanziare: [],
    loadingRiepilogoRichieste: false,
    cupSelezionato: null,
    snackbar: {
      color: null,
      text: "",
      value: false,
    },
  }),
  methods: {
    initialize() {
      if (
        this.isRoleResponsabileControllo ||
        this.isRoleIncaricatoControllo ||
        this.isRoleConsulenza ||
        this.isRoleRup
      ) {
        this.getProgrammiOrientamento();
        this.getRiepilogoFinanziario();
        this.getRiepilogoTarget();
        this.getAllRichiesteAnticipo();
        this.loadinPage = true;
      }
    },
    async getProgrammiOrientamento() {
      this.programmi =
        await AssegnazioneRisorseRepository.getRiepilogoProgrammiOrientamento();
    },
    async getRiepilogoFinanziario() {
      this.risorseFinanziare =
        await AssegnazioneRisorseRepository.getRiepilogoFinanziario();
    },
    async getRiepilogoTarget() {
      this.target = await AssegnazioneRisorseRepository.getRiepilogoTarget();
    },
    async getAllRichiesteAnticipo() {
      if (this.isRoleRup || this.isRoleConsulenza) {
        this.richiesteAnticipo =
          await AssegnazioneRisorseRepository.getAllRichiesteAnticipo();
        this.loadingRiepilogoRichieste = true;
      }
    },
    async esportaSuExcel() {
      const programmi = this.filtraProgrammi();
      const resFinanze = this.filtraFinanze();
      const target = this.filtraTarget();
      const richieste = this.filtraRichieste();

      const request = JSON.stringify({
        datiProgrammi: programmi,
        datiFinanziari: resFinanze,
        datiTarget: target,
        datiRichiesteAnticipo: richieste,
      });

      const formBody = new FormData();
      formBody.append("reportAssegnazioneRisorse", request);
      await AssegnazioneRisorseRepository.getFileExcelRiepiloghi(formBody);
    },
    async estrazione() {
      this.recuperoDati = true;
      let anno = this.$store.state.anno ?? 0;
      let idStrutture = this.$store.state.idStrutture ?? 0;
      const res = await AssegnazioneRisorseRepository.getFileExcelEstrazione(
        anno,
        idStrutture
      );
      if (res.status === 200) {
        const file = new Blob([res.data], {
          type: res.data.type,
        });
        const objectUrl = URL.createObjectURL(file);
        const linkToFile = document.createElement("a");
        linkToFile.href = objectUrl;
        linkToFile.download = "report_estrazione_risorse_" + anno + ".xlsx";
        linkToFile.click();
        URL.revokeObjectURL(file);
        this.setSnackbar("success", "Estrazione andata a buon fine.");
        this.recuperoDati = false;
      } else {
        this.setSnackbar("red accent-2", "Errore nella generazione dell'excel");
        this.recuperoDati = false;
      }
    },
    filtraProgrammi() {
      return this.programmi.filter((element) => {
        return (
          (this.idStrutture != null
            ? element.idStrutture === this.idStrutture
            : element.idStrutture > 0) &&
          (this.anno != null ? element.anno === this.anno : element.anno > 0)
        );
      });
    },
    filtraFinanze() {
      return this.risorseFinanziare.filter((element) => {
        return (
          (this.idStrutture != null
            ? element.programmaOrientamento.idStrutture === this.idStrutture
            : element.programmaOrientamento.idStrutture > 0) &&
          (this.anno != null
            ? parseInt(element.programmaOrientamento.anno) ===
              parseInt(this.anno)
            : element.programmaOrientamento.anno > 0)
        );
      });
    },
    filtraTarget() {
      return this.target.filter((element) => {
        return (
          (this.idStrutture != null
            ? element.programmaOrientamento.idStrutture === this.idStrutture
            : element.programmaOrientamento.idStrutture > 0) &&
          (this.anno != null
            ? element.programmaOrientamento.anno === this.anno
            : element.programmaOrientamento.anno > 0)
        );
      });
    },
    filtraRichieste() {
      return this.richiesteAnticipo.filter((element) => {
        return (
          (this.idStrutture != null
            ? element.richiestaAnticipo.idProgramma.idStrutture ===
              this.idStrutture
            : element.richiestaAnticipo.idProgramma.idStrutture > 0) &&
          (this.anno != null
            ? element.richiestaAnticipo.idProgramma.anno === this.anno
            : element.richiestaAnticipo.idProgramma.anno > 0)
        );
      });
    },
    uploadCompletato() {
      window.location.reload();
    },
    setSnackbar(color, text) {
      this.recuperoDati = false;
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.value = true;
    },
  },
};
</script>
